import type { Props as CartItemInterface } from '@hypercodestudio/basler-components/dist/components/modules/CartItem.vue';
import type { CartItemFragment } from '~/lib/Shop/generated/schema';
import { DecoratedProduct } from '~/utils/shop/DecoratedProduct';
import { createCartItemPrices } from './createCartItemPrices';

export function createCartItem(
  item: CartItemFragment,
  locale: string,
  generateDetailLink: (product: DecoratedProduct) => string,
  showPrices = true,
  translations: Record<string, string> = {},
  includeTaxes = false
): CartItemInterface {
  const decoratedProduct = DecoratedProduct.build(item.product, {}, locale);

  return {
    id: item.uid,
    title: decoratedProduct.series,
    text: decoratedProduct.modelName,
    media: decoratedProduct.getProductImage({
      // this seems to be the original resolution for all product images
      width: 800,
      height: 600,
      keepAspectRatio: true,
      fit: 'fill',
      widths: [110, 220]
    }),
    link: {
      uri: generateDetailLink(decoratedProduct)
    },
    amountLabel: translations['cart.common.amount.label'] ?? '',
    amount: item.quantity,
    // XXX: reuse Price from decorated product? (including display logic?)
    price: showPrices
      ? createCartItemPrices(item.prices, locale, includeTaxes)
      : '',
    priceLabel: translations['cart.common.price.label'] ?? '',
    removeActionLabel: translations['cart.common.removeFromCart.label']
  };
}
