import type { CartItemPricesFragment } from '~/lib/Shop/generated/schema';
import { formatMoney } from '~/utils/shop/formatMoney';

export function createCartItemPrices(
  prices: CartItemPricesFragment | undefined | null,
  locale: string,
  includeTaxes = false
): string {
  if (includeTaxes) {
    return formatMoney(prices?.row_total_including_tax, locale);
  }

  return formatMoney(prices?.price, locale);
}
