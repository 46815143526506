import type { Cart } from '@hypercodestudio/basler-components/dist/components/modules/CartQuickView.vue';
import type { HyperlinkInterface } from '@hypercodestudio/basler-components/dist/components/helpers/HyperLink.vue';
import { createCartPrices } from './createCartPrices';
import { createCartItem } from './createCartItem';
import type { CartFragment } from '~/lib/Shop/generated/schema';
import { isDefined } from '~/utils/guards/isDefined';
import type { DecoratedProduct } from '~/utils/shop/DecoratedProduct';
import type { CartType } from '~/stores/cartStore/model';

type CreateCartOptions = {
  id: string;
  cartType: CartType;
  locale: string;
  title: string;
  cart: CartFragment | null;
  translations: Record<string, string>;
  createDetailLink: (product: DecoratedProduct) => string;
  isActive?: boolean;
  gotoCartLink: HyperlinkInterface;
  footerLink: HyperlinkInterface;
  showPrices?: boolean;
  includeTaxes?: boolean;
};

export function createCart({
  id,
  cartType,
  locale,
  title,
  cart,
  translations,
  createDetailLink,
  isActive = false,
  gotoCartLink,
  footerLink,
  showPrices = true,
  includeTaxes = false
}: CreateCartOptions): Cart {
  return {
    id,
    title,
    isActive,
    summary: {
      itemCountText: translations['cart.common.article.label'] ?? '',
      itemCount: cart?.items?.length ?? 0,
      subtotalText: showPrices
        ? translations['cart.common.subtotal.label'] ?? ''
        : '',
      subtotal: showPrices
        ? createCartPrices(cart?.prices, locale, includeTaxes)
        : ''
    },
    items:
      cart?.items
        ?.filter(isDefined)
        .map((item) =>
          createCartItem(
            item,
            locale,
            createDetailLink,
            showPrices,
            translations,
            includeTaxes
          )
        ) ?? [],
    ctaLinkItem: {
      link: gotoCartLink,
      title:
        translations[`cart.${cartType}.showCart.label`] ??
        translations['cart.common.showCart.label'] ??
        ''
    },
    moreItemsText: translations['cart.common.moreItems.label'] ?? '',
    footer: {
      text: translations['cart.footer.text.label'] ?? '',
      linkItem: {
        link: footerLink,
        title: translations['cart.footer.link.label'] ?? ''
      }
    }
  };
}
