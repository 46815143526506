import type { CartPricesFragment } from '~/lib/Shop/generated/schema';
import { formatMoney } from '~/utils/shop/formatMoney';

export function createCartPrices(
  prices: CartPricesFragment | undefined | null,
  locale: string,
  includeTaxes = false
): string {
  if (includeTaxes) {
    return formatMoney(prices?.subtotal_including_tax, locale);
  }

  return formatMoney(prices?.subtotal_excluding_tax, locale);
}
