import type { MediaImageInterface } from '@hypercodestudio/basler-components/dist/components/elements/media/types/MediaImage.vue';
import type { Props as ArticleTeaserInterface } from '@hypercodestudio/basler-components/dist/components/modules/ArticleTeaser.vue';
import type { MediaItemInterface } from '@hypercodestudio/basler-components/dist/components/elements/media/Media.vue';
import type { AlgoliaContentfulPage } from '~/lib/AlgoliaService/models/AlgoliaContentfulPage';
import type { AlgoliaProduct } from '~/lib/AlgoliaService/models/AlgoliaProduct';
import { isAlgoliaContentfulPage } from '~/utils/guards/isAlgoliaContentfulPage';
import { isAlgoliaProduct } from '~/utils/guards/isAlgoliaProduct';
import { generateImageObject } from '~/utils/generateImageObject';
import { buildLinkInterface } from '~/utils/buildLinkInterface';
import { createAlgoliaProductImage } from '~/utils/createAlgoliaProductImage';
import { PRODUCTS_TAG } from '~/lib/AlgoliaService/constants';
import type { SearchTagModel } from '~/utils/createDictKeysForSearchTags';
import { dotString } from '~/utils/dotString';
import {
  type DownloadEntryModel,
  isDownloadEntryModel
} from '~/lib/DownloadService/model';

const TAGS_SEPARATOR = ' | ';

export function transformSearchResultToArticleTeaser(
  result: AlgoliaContentfulPage | AlgoliaProduct | DownloadEntryModel,
  tags: ReadonlyArray<SearchTagModel>,
  locale: string
): ArticleTeaserInterface | null {
  if (isAlgoliaContentfulPage(result)) {
    return transformAlgoliaContentfulPageToArticleTeaser(result, tags, locale);
  }
  if (isAlgoliaProduct(result)) {
    return transformAlgoliaProductToArticleTeaser(result, tags, locale);
  }
  if (isDownloadEntryModel(result)) {
    return transformAlgoliaDocumentToArticleTeaser(result);
  }

  return null;
}

function transformAlgoliaContentfulPageToArticleTeaser(
  page: AlgoliaContentfulPage,
  tags: ReadonlyArray<SearchTagModel>,
  locale: string
): ArticleTeaserInterface {
  const image = generateImageObject(page.teaser_image_url, {
    fit: 'pad',
    bgColor: 'rgb:ffffff'
  });
  if (image) {
    (image.content as MediaImageInterface).alt = page.teaser_image_alt;
  }

  const subtitle = tags
    .filter((tag) => page.tags.includes(tag.value))
    .map((tag) => tag.label)
    .join(TAGS_SEPARATOR);

  return {
    title: page.teaser_title,
    subtitle,
    media: image,
    link: buildLinkInterface(page.url, locale),
    excerpt: page.content ?? ''
  };
}

function transformAlgoliaProductToArticleTeaser(
  product: AlgoliaProduct,
  tags: ReadonlyArray<SearchTagModel>,
  locale: string
): ArticleTeaserInterface {
  const media: MediaItemInterface = createAlgoliaProductImage(product, {
    widths: [195, 390]
  }) ?? {
    type: 'image',
    content: {
      src: product.thumbnail_url ?? ''
    }
  };
  const subtitle = tags
    .filter((tag) => tag.value === PRODUCTS_TAG)
    .map((tag) => tag.label)
    .join(TAGS_SEPARATOR);

  return {
    title: product.name ?? '',
    subtitle,
    media,
    link: buildLinkInterface(buildPdpUrl(locale, product.url_key)),
    excerpt: product.seo_headline ?? ''
  };
}

function transformAlgoliaDocumentToArticleTeaser(
  download: DownloadEntryModel
): ArticleTeaserInterface | null {
  const url = `#download=${download.id}`;
  const link = buildLinkInterface(url);
  const excerpt = dotString(download.metaDescription ?? '', 350);
  const subtitle = download.downloadCategory;

  return {
    title: download.name ?? '',
    subtitle,
    media: null,
    link,
    excerpt
  };
}
