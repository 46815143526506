import { acceptHMRUpdate, defineStore } from 'pinia';
import type {
  Cart,
  NoItems
} from '@hypercodestudio/basler-components/dist/components/modules/CartQuickView.vue';
import type { Props as CartItemInterface } from '@hypercodestudio/basler-components/dist/components/modules/CartItem.vue';
import type { HyperlinkInterface } from '@hypercodestudio/basler-components/dist/components/helpers/HyperLink.vue';
import { useCartStore } from '../cartStore/cartStore';
import type { CartType } from '../cartStore/model';
import { useCartLink } from '../cartStore/useCartLink';
import type { CartWidgetStoreState } from './model';
import { createCart } from './helper/createCart';
import { useCreateProductDetailLink } from '~/composables/useCreateProductDetailLink';
import { isDefined } from '~/utils/guards/isDefined';

const DEFAULT_LINK: HyperlinkInterface = {
  uri: '#'
};

export const useCartWidgetStore = defineStore('shop-cart-widget', {
  state: (): CartWidgetStoreState => ({
    footerLink: DEFAULT_LINK,
    noItemsLink: DEFAULT_LINK
  }),
  getters: {
    carts(): Cart[] {
      const { $textDictionary, $shopStore } = this.$nuxt;
      const cartStore = useCartStore();
      const createDetailLink = useCreateProductDetailLink();

      // We decided to hard code this for now until we have a configurable
      // solution.
      // @see https://gcp.baslerweb.com/jira/browse/DBP-886
      const includeTaxes = $shopStore.value?.toLowerCase() === 'china_cn';

      return Object.entries(cartStore.carts)
        .map(([cartType, cartFragment]) => {
          if (
            cartFragment == null ||
            cartFragment.items == null ||
            cartFragment.items.length === 0
          ) {
            return undefined;
          }

          return createCart({
            id: cartType,
            cartType: cartType as CartType,
            locale: cartStore.locale,
            title:
              $textDictionary.value[`cart.${cartType}.title.label`] ??
              $textDictionary.value['cart.cart.title.label'] ??
              '',
            cart: cartFragment,
            translations: $textDictionary.value,
            createDetailLink,
            isActive:
              cartFragment.items.length > 0 &&
              cartType === cartStore.activeCartType,
            gotoCartLink: {
              uri: useCartLink(cartType as CartType),
              target: '_self',
              external: true
            } satisfies HyperlinkInterface,
            footerLink: this.footerLink,
            showPrices: cartType !== 'requestCart',
            includeTaxes
          });
        })
        .filter(isDefined);
    },
    /**
     * Returns the active cart in the Widget **only**!
     * This is currently not synced with the cartStore as the frontend
     * component controls the "active" cart and manipulates the given
     * cart ref.
     */
    activeCart(): Cart | undefined {
      return this.carts.find((cart) => cart.isActive);
    },
    noItems(): NoItems {
      const { $textDictionary } = this.$nuxt;

      return {
        text: $textDictionary.value['cart.common.noItems.label'] ?? '',
        linkItem: {
          link: this.noItemsLink,
          title: $textDictionary.value['cart.common.noItemsLink.label'] ?? ''
        },
        footer: {
          text: $textDictionary.value['cart.footer.text.label'] ?? '',
          linkItem: {
            link: this.footerLink,
            title: $textDictionary.value['cart.footer.link.label'] ?? ''
          }
        }
      };
    },
    /**
     * Returns the number of items in the current active cart (not the amount).
     */
    activeCartItemCount(): number | undefined {
      // return `undefined` instead of 0
      return this.activeCart?.items?.length || undefined;
    },
    /**
     * Returns the number of items of all carts (not the amount).
     * Returns `undefined` if no carts are available.
     */
    cartItemCount(): number | undefined {
      if (this.carts.length === 0) {
        return;
      }

      // return `undefined` instead of 0
      return this.carts.reduce(
        (collector, cart) => collector + cart.items.length,
        0
      );
    },
    /**
     * Returns the current cart link.
     * This is NOT in sync with the cart store, see {@link activeCart}.
     */
    currentCartLink(): string | undefined {
      const cartType = this.activeCart?.id as CartType;

      if (!cartType) {
        return;
      }

      return useCartLink(cartType);
    }
  },
  actions: {
    async handleRemove(item: CartItemInterface) {
      const logger = useLogger();
      const activeCart = this.activeCart;
      if (!activeCart) {
        logger.warn(`no cart active - can not remove item ${item.id}`);

        return;
      }

      // this assumes, that a product can only be once inside a cart (and
      // only the quantity can be in-/decreased).
      const cartItem: CartItemInterface | undefined = activeCart.items?.find(
        (cartItem) => item.id === cartItem?.id
      );

      if (!cartItem) {
        logger.warn(
          `could not find item with uid ${item.id} in current active cart %O - aborting`,
          activeCart
        );

        return;
      }

      const cartStore = useCartStore();
      const activeCartType = activeCart.id as CartType;

      await cartStore.removeFromCart(cartItem.id, activeCartType);

      // the widget manipulates the carts and sets the active.
      // XXX: merge with active in cartStore?
      const activeCartInCartStore = cartStore.carts[activeCartType];
      // if the last item was removed, set the active cart to the last
      // available cart
      if (
        !activeCartInCartStore ||
        !activeCartInCartStore.items ||
        activeCartInCartStore?.items.length === 0
      ) {
        const nextCart = this.carts.find((cart) => cart.items.length > 0);
        if (nextCart) {
          cartStore.activeCartType = nextCart.id as CartType;
        }
      }
    }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartWidgetStore, import.meta.hot));
}
