<template>
  <PageHeader
    :logo-link="logoLink"
    :logo-text="$globalPageSettings?.companyName ?? ''"
    :main-navigation="mainNavigationItems"
    :nav-top-items="navTopItems"
    :nav-action-bar-items="navActionBarItems"
    :search-label="searchLabel"
    :search-title="searchTitle"
    :search-box="searchBox"
    :cart-quick-view="cartQuickView"
    @cart-item-remove="cartWidgetStore.handleRemove"
    @search-value-change="handleSearchValueChange"
    @search-form-submit="handleSearchFormSubmit"
    @search-type-change="handleSearchTypeChange"
  />
  <ShopCartToastContainer />
</template>

<script setup lang="ts">
import { PageHeader } from '@hypercodestudio/basler-components';
import type { MainNavigationInterface } from '@hypercodestudio/basler-components/dist/models/navigation/MainNavigation';
import type { NavTopItem } from '@hypercodestudio/basler-components/dist/models/navTopItem';
import type { HyperlinkInterface } from '@hypercodestudio/basler-components/dist/components/helpers/HyperLink.vue';
import { useCreateMainNavItem } from '~/composables/useCreateMainNavItem';
import { useBuildLinkInterface } from '~/composables/useBuildLinkInterface';
import { isDefined } from '~/utils/guards/isDefined';
import type { NavigationItemFragment } from '~/lib/ContentfulGraphqlService';
import { isNavigationItemFragment } from '~/utils/guards/isNavigationItemFragment';
import { deepExtract } from '~/utils/deepExtract';
import { useSearchBox } from '~/composables/useSearchBox';
import { convertContentfulToWebappLocaleCode } from '~/utils/convertContentfulToWebappLocaleCode';
import { useCartWidgetStore } from '~/stores/cartWidgetStore/cartWidgetStore';
import { useCartStore } from '~/stores/cartStore/cartStore';
import { useGeoinformationStore } from '~/stores/geoinformationStore/geoinformationStore';

const { $globalPageSettings, $shopStore, $breakpoints, $textDictionary } =
  useNuxtApp();
const locale = useLocale();
const slug = useSlug();
const cartStore = useCartStore();
const geoinformationStore = useGeoinformationStore();
const logger = useLogger();

watchEffect(() =>
  cartStore.$patch((state) => {
    state.store = $shopStore.value;
    state.locale = locale.value;
  })
);

const {
  searchBox,
  handleSearchFormSubmit,
  handleSearchTypeChange,
  handleSearchValueChange
} = useSearchBox($globalPageSettings);

const footerLink = computed(() =>
  useBuildLinkInterface($globalPageSettings.value?.cartFooterLink)
);
const noItemsLink = computed(() =>
  useBuildLinkInterface($globalPageSettings.value?.cartNoItemsLink)
);

const cartWidgetStore = useCartWidgetStore();

watchEffect(() =>
  cartWidgetStore.$patch((state) => {
    state.footerLink = footerLink.value;
    state.noItemsLink = noItemsLink.value;
  })
);

const mainNavigation = computed(
  () =>
    $globalPageSettings.value?.headerMainNavigation?.navigationItemsCollection
      ?.items
);

const firstLevelParentNavigationItemIndex = computed(() => {
  if (!slug.value) {
    return -1;
  }

  let parents: NavigationItemFragment[] = [];

  if ($globalPageSettings.value) {
    deepExtract(
      $globalPageSettings.value.headerMainNavigation,
      (value, _, _parents, stop) => {
        if (
          isNavigationItemFragment(value) &&
          value.internalPage?.metadata?.slug === slug.value
        ) {
          parents = [..._parents.filter(isNavigationItemFragment), value];
          stop();
        }

        return false;
      }
    );
  }

  return mainNavigation.value?.findIndex(
    (item) =>
      item?.internalPage?.metadata?.slug ===
      parents[0]?.internalPage?.metadata?.slug
  );
});

const mainNavigationItems = computed<MainNavigationInterface>(() => ({
  showTabs: mainNavigation.value
    ?.map((item, index) => (item?.displayedAsTabs ? index : -1))
    .filter((item) => item !== -1),
  items:
    mainNavigation.value
      ?.map((item, index) =>
        useCreateMainNavItem(
          item,
          index === firstLevelParentNavigationItemIndex.value
        )
      )
      .filter(isDefined) ?? [],
  backButtonLabel:
    $textDictionary.value['pageHeader.navigation.mobile.back'] ?? 'Back',
  nextButtonLabel:
    $textDictionary.value['pageHeader.navigation.mobile.next'] ?? 'Next',
  closeButtonLabel:
    $textDictionary.value['pageHeader.navigation.mobile.close'] ?? 'Close'
}));

// Begin Workaround for QuickCartView:
// we MUST keep the reference to this array and only manipulate
// the entries inside to get the cart widget to update.
const cartsRef = ref([...cartWidgetStore.carts]);
const cartQuickView = computed(() => ({
  carts: cartsRef,
  noItems: cartWidgetStore.noItems,
  isOpen: true
}));

watch(
  () => cartWidgetStore.carts,
  () => {
    cartsRef.value.splice(0, cartsRef.value.length);
    cartsRef.value.push(...cartWidgetStore.carts);
  }
);
// End Workaround

const navTopItems = computed(
  () =>
    [
      ...($globalPageSettings.value?.headerServiceNavigation?.navigationItemsCollection?.items
        .filter(
          (item) => !isDefined(item?.showInNavigation) || item?.showInNavigation
        )
        .map(
          (item) =>
            ({
              label: item?.linkText ?? '',
              // XXX: wrong type in Hypercode components?
              link: useBuildLinkInterface(item) as any,
              iconName: item?.icon ?? ''
            } satisfies NavTopItem)
        ) ?? []),
      {
        label: geoinformationStore.currentRegionLabel,
        link: {
          uri: {
            // without the path the link does not work on the homepage
            path: '.',
            hash: '#regionselector'
          }
        } satisfies HyperlinkInterface,
        iconName: 'globe'
      }
    ] satisfies NavTopItem[]
);

// the cart links needs the absolute url to be treated as default link
// (not <RouterLink>).
const cartLink = computed(() => {
  if (
    !cartWidgetStore.currentCartLink ||
    // desktop should not have a basket link
    !$breakpoints.specialHeaderBreakpoint
  ) {
    return {};
  }

  return {
    uri: cartWidgetStore.currentCartLink,
    target: '_self',
    external: true
  };
});

const navActionBarItems = computed(() => [
  {
    name: 'search',
    type: 'search',
    title: 'Search',
    link: useBuildLinkInterface('/search/'),
    iconName: 'action_bar_magnifier'
  },
  {
    name: 'cart',
    type: 'cart',
    title: $textDictionary.value['cart.cart.title.label'] ?? 'Cart',
    link: cartLink.value,
    iconName: 'action_bar_shopping_cart',
    badgeNumber: cartWidgetStore.cartItemCount
  },
  {
    name: 'profile',
    type: 'link',
    title:
      $textDictionary.value['pageHeader.actions.shopLogin'] ?? 'Shop login',
    link: profileLink.value,
    iconName: 'action_bar_profile'
  }
]);

const searchLabel = computed(() => 'Search...');
const searchTitle = computed(() => 'Search');
const logoLink = computed(() => ({
  uri: `/${convertContentfulToWebappLocaleCode(locale.value)}/`
}));
const profileLink = computed(() => ({
  uri: cartStore.profileLink,
  target: '_self',
  external: true
}));

onMounted(() => {
  // this loads all carts for which a cookie value exists.
  // we trigger this on the client side as this is user data and should not
  // be cached (e.g. in a cdn).
  cartStore.init().catch((e) => {
    // the cart store has internal error handling. just in case wrap with an
    // additional try-catch.
    logger.error('PageHeader', 'could not load cart', e);
  });
});
</script>
